import { NgModule } from '@angular/core';

import { MessagePanelModule } from '../../../ui-components/components/panels/message-panel/message-panel.module';
import { UIComponentsModule } from '../../../ui-components/ui-components.module';
import { ComprasOrdemDeCompraStatusComponent } from './compras-ordem-de-compra-status.component';

@NgModule({
    imports: [UIComponentsModule, MessagePanelModule],
    exports: [ComprasOrdemDeCompraStatusComponent],
    declarations: [ComprasOrdemDeCompraStatusComponent],
})
export class ComprasOrdemDeCompraStatusModule {}
