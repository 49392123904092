import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { hasRequiredField } from 'src/app/app.functions';

import { AppHelper } from '../../../app.helper';
import { ListDataSource } from '../../../core/models/multi-dropbox-datasource.model';

@Component({
    selector: 'otk-cmp-ordem-status-multi-dropbox',
    templateUrl: './cmp-ordem-status-multi-dropbox.component.html',
    styleUrls: ['./cmp-ordem-status-multi-dropbox.component.scss'],
})
export class CmpOrdemStatusMultiDropboxComponent implements OnInit {
    data: ListDataSource[] = [
        {
            id: 'N',
            text: 'Não Requer Aprovação',
        },
        {
            id: 'G',
            text: 'Aguardando Aprovação',
        },
        {
            id: 'P',
            text: 'Aprovada Parcialmente',
        },
        {
            id: 'A',
            text: 'Aprovada',
        },
        {
            id: 'R',
            text: 'Reprovada',
        },
        {
            id: 'F',
            text: 'Finalizada',
        },
    ];

    itemsSelected: any[] = [];

    placeholder: string = 'Todos';
    @Input() label: string = 'Status';
    @Input() control: FormControl;
    @Input() width: string = '100%';
    @Input() showClearButton: boolean = true;
    @Input() selectionModeValue: string = 'all';
    @Input() selectAllModeValue: string = 'page';

    constructor() {}

    ngOnInit() {
        AppHelper.setCtrlValue(this.control, []);
    }

    get _caption(): string {
        return this.label.replace(/\*/g, ' ');
    }

    get _captionLabel(): string {
        if (this.control && hasRequiredField(this.control)) {
            let t = this.label.replace(/\*/g, ' ');
            return `${t} *`;
        }
        return this.label;
    }

    actionSelectionChanged(e: any) {
        //  console.log(`ITEM SELECTED`, e);

        if (!e || !this.itemsSelected) {
            return;
        }

        let t = this.itemsSelected.map((e) => {
            return e.id;
        });

        AppHelper.setCtrlValue(this.control, t);
        this.atualizar();
    }

    private atualizar() {
        if (this.itemsSelected.length > 0) {
            if (this.itemsSelected.length == 1) {
                this.placeholder = this.itemsSelected.length + ' Selecionado';
            } else {
                this.placeholder = this.itemsSelected.length + ' Selecionados';
            }
        } else {
            this.placeholder = 'Todos';
        }
    }
}
