import { NgModule } from '@angular/core';

import { DxPivotGridModule } from 'devextreme-angular';
import { UIComponentsModule } from '../../../ui-components/ui-components.module';
import { OrdemCotacoesPivotGridDetailComponent } from './ordem-cotacoes-pivot-grid-detail.component';

@NgModule({
    imports: [UIComponentsModule, DxPivotGridModule],
    exports: [OrdemCotacoesPivotGridDetailComponent],
    declarations: [OrdemCotacoesPivotGridDetailComponent],
})
export class OrdemCotacoesPivotGridModule {}
