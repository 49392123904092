import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material';

import { CmpFreteRadioButtonComponent } from './cmp-frete-radio-button.component';

@NgModule({
    imports: [ReactiveFormsModule, FlexLayoutModule, MatRadioModule],
    exports: [CmpFreteRadioButtonComponent],
    declarations: [CmpFreteRadioButtonComponent],
    entryComponents: [],
    providers: [],
})
export class FreteSharedComponentsModule {}
