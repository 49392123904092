import { NgModule } from '@angular/core';

import { UIComponentsModule } from '../../../ui-components/ui-components.module';
import { ComprasFornecedorPedidosAvaliacaoButtonComponent } from './compras-fornecedor-pedidos-avaliacao-button.component';

@NgModule({
    imports: [UIComponentsModule],
    exports: [ComprasFornecedorPedidosAvaliacaoButtonComponent],
    declarations: [ComprasFornecedorPedidosAvaliacaoButtonComponent],
})
export class ComprasFornecedorPedidosAvaliacaoModule {}
