import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import DataSource from 'devextreme/data/data_source';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';

import { hasRequiredField } from '../../../app.functions';
import { AppHelper } from '../../../app.helper';
import { AppSettings } from '../../../app.settings';

@Component({
    selector: 'otk-cmp-fornecedor-multi-dropbox',
    templateUrl: './cmp-fornecedor-multi-dropbox.component.html',
    styleUrls: ['./cmp-fornecedor-multi-dropbox.component.scss'],
})
export class CmpFornecedorMultiDropboxComponent implements OnInit {
    dataSource: any = null;
    itemsSelected: any[] = [];

    placeholder: string = 'Todos';

    @Input() label: string = 'Fornecedor';
    @Input() control: FormControl;
    @Input() width: string = '100%';
    @Input() showClearButton: boolean = true;
    @Input() selectionModeValue: string = 'all';
    @Input() selectAllModeValue: string = 'page';

    constructor(private _http: HttpClient) {}

    ngOnInit() {
        AppHelper.setCtrlValue(this.control, []);

        this.carregar()
            .pipe(take(1))
            .subscribe(
                (r) => {
                    this.dataSource = new DataSource({
                        paginate: true,
                        pageSize: 50,
                        store: r,
                    });
                    this.atualizar();
                },
                (e) => {
                    //console.log("Error", e);
                    this.dataSource = null;
                }
            );
    }
    ngOnChanges() {
        this.carregar();
    }

    private carregar(): Observable<any[]> {
        this.dataSource = null;

        return this._http
            .get<any[]>(
                `${AppSettings.API_ENDPOINT_CADASTROS}/cadastro/fornecedor/lista`
            )
            .pipe(
                map((r) => {
                    return r.map((e) => {
                        return {
                            text: e.nomeFornecedorFmt,
                            id: e.codigoDoFornecedor,
                        };
                    });
                })
            );
    }

    actionSelectionChanged(e: any) {
        // console.log(`ITEM SELECTED`, e);

        if (!e || !this.itemsSelected) {
            return;
        }

        let t = this.itemsSelected.map((e) => {
            return e.id;
        });

        AppHelper.setCtrlValue(this.control, t);
        this.atualizar();
    }

    get _caption(): string {
        return this.label.replace(/\*/g, ' ');
    }

    get _captionLabel(): string {
        if (this.control && hasRequiredField(this.control)) {
            let t = this.label.replace(/\*/g, ' ');
            return `${t} *`;
        }
        return this.label;
    }

    private atualizar() {
        if (this.itemsSelected.length > 0) {
            if (this.itemsSelected.length == 1) {
                this.placeholder = this.itemsSelected.length + ' Selecionado';
            } else {
                this.placeholder = this.itemsSelected.length + ' Selecionados';
            }
        } else {
            this.placeholder = 'Todos';
        }
    }
}
