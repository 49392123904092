import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import DataSource from 'devextreme/data/data_source';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';

import { hasRequiredField } from '../../../app.functions';
import { AppHelper } from '../../../app.helper';
import { AppSettings } from '../../../app.settings';

@Component({
    selector: 'otk-cmp-classificacao-auxiliar-financeiro-multi-dropbox',
    templateUrl:
        './cmp-classificacao-auxiliar-financeiro-multi-dropbox.component.html',
    styleUrls: [
        './cmp-classificacao-auxiliar-financeiro-multi-dropbox.component.scss',
    ],
})
export class CmpClassificacaoAuxiliarFinanceiroMultiDropboxComponent
    implements OnInit
{
    itemsSelected: any[] = [];
    dataSource: any = null;

    placeholder: string = 'Todos';

    @Input() label: string = 'Classificação Auxiliar';
    @Input() control: FormControl;
    @Input() width: string = '100%';
    @Input() showClearButton: boolean = true;
    @Input() selectionModeValue: string = 'all';
    @Input() selectAllModeValue: string = 'page';

    constructor(private _http: HttpClient) {}

    ngOnInit() {
        AppHelper.setCtrlValue(this.control, []);

        this.carregar()
            .pipe(take(1))
            .subscribe(
                (r) => {
                    // console.log('DATA', r);
                    this.dataSource = new DataSource({
                        paginate: true,
                        pageSize: 50,
                        store: r,
                    });

                    if (this.control && hasRequiredField(this.control)) {
                        this.atualizarSelecionados();
                    }
                },
                (e) => {
                    //console.log("Error", e);
                    this.dataSource = null;
                }
            );
    }

    ngOnChanges() {
        this.carregar();
    }

    get _caption(): string {
        return this.label.replace(/\*/g, ' ');
    }

    get _captionLabel(): string {
        if (this.control && hasRequiredField(this.control)) {
            let t = this.label.replace(/\*/g, ' ');
            return `${t} *`;
        }
        return this.label;
    }

    private carregar(): Observable<any[]> {
        this.dataSource = null;
        return this._http
            .get<any[]>(
                `${AppSettings.API_ENDPOINT_FINANCEIRO}/financeiro/classificacaoauxiliar/lista`
            )
            .pipe(
                map((r) => {
                    let t = r.map((e) => {
                        return {
                            text: e.descricaoDaClassificacaoAuxiliar,
                            id: e.codigoDaClassificacaoAuxiliar,
                            ativo: e.ativo,
                            crUtilizar: e.crUtilizar,
                            cpUtilizar: e.cpUtilizar,
                        };
                    });

                    t = t.filter((e) => e.crUtilizar || e.cpUtilizar);
                    return t;
                })
            );
    }

    private atualizarSelecionados(): void {
        if (this.itemsSelected.length > 0) {
            if (this.itemsSelected.length == 1) {
                this.placeholder = this.itemsSelected.length + ' Selecionado';
            } else {
                this.placeholder = this.itemsSelected.length + ' Selecionados';
            }
        } else {
            this.placeholder = 'Todos';
        }
    }

    actionSelectionChanged(e: any) {
        // console.log(`ITEM SELECTED`, e);

        if (!e || !this.itemsSelected) {
            return;
        }

        let t = this.itemsSelected.map((e) => {
            return e.id;
        });

        AppHelper.setCtrlValue(this.control, t);

        this.atualizarSelecionados();
    }
}
