import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DxPivotGridComponent } from 'devextreme-angular';

import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AppSettings } from '../../../app.settings';

@Component({
    selector: 'otk-ordem-cotacoes-pivot-grid-detail',
    templateUrl: './ordem-cotacoes-pivot-grid-detail.component.html',
    styleUrls: ['./ordem-cotacoes-pivot-grid-detail.component.scss'],
})
export class OrdemCotacoesPivotGridDetailComponent implements OnInit {
    @Input() numeroDaOrdem: number = 0;
    @ViewChild(DxPivotGridComponent) pivotGrid: DxPivotGridComponent;

    dataSource: any[] = [];
    pivotGridDataSource: any;

    grids: Array<{
        icon: string;
        style: string;
        hint: string;
        disabled: boolean;
    }> = [];

    constructor(private _http: HttpClient) {}

    ngOnInit() {}

    ngOnChanges() {
        this.carregarMapaDeCotacoes(this.numeroDaOrdem);
    }

    actionCellPrepared(e: any) {
        if (e.area === 'data') {
            const n = this.dataSource.filter(
                (r: any) =>
                    r.nomeDoFornecedor == e.cell.columnPath[0] &&
                    r.descricaoDoProduto == e.cell.rowPath
            );
            if (n) {
                const x = n.filter((r) => r.selecionada == true);
                if (x && x.length > 0) {
                    e.cellElement.className =
                        'background-color-blue color-white';
                    return;
                } else {
                    return;
                }
            }
        }

        if (e.area === 'column' && e.cell.expanded) {
            e.cellElement.className = '';
        }
    }

    carregarMapaDeCotacoes(numeroDaOrdem: number) {
        this._carregarCotacoesItens(numeroDaOrdem)
            .pipe(
                tap(() => (this.dataSource = [])),
                take(1)
            )
            .subscribe((r) => {
                this.dataSource = r;
                this._configurarGrid();
            });
    }

    _carregarCotacoesItens(numeroDaOrdem: number): Observable<any[]> {
        if (!numeroDaOrdem) {
            return of([]);
        }

        return this._http.get<any[]>(
            `${AppSettings.API_ENDPOINT_COMPRAS}/compras/ordem/${numeroDaOrdem}/cotacoesitens`
        );
    }

    _configurarGrid() {
        const flds = [
            {
                caption: 'Produto',
                dataField: 'descricaoDoProduto',
                width: 240,
                area: 'row',
            },
            {
                caption: 'Fornecedor',
                dataField: 'nomeDoFornecedor',
                width: 180,
                area: 'column',
                expanded: true,
            },
            {
                caption: 'Produtos + Impostos',
                dataField: 'valorTotalItem',
                format: {
                    type: 'fixedPoint',
                    precision: 2,
                },
                area: 'data',
                summaryType: 'sum',
            },
        ];

        const filterFrete = this.dataSource.filter((e) => e.totalFrete > 0);
        if (filterFrete && filterFrete.length > 0) {
            flds.push({
                caption: 'Frete',
                format: {
                    type: 'fixedPoint',
                    precision: 2,
                },
                area: 'data',

                dataField: 'totalFrete',
                summaryType: 'avg',
            });
        }

        this.pivotGridDataSource = {
            store: this.dataSource,
            fields: flds,
        };
    }
}
