import { NgModule } from '@angular/core';

import { UIComponentsModule } from '../../ui-components/ui-components.module';
import { CmpQuantidadeEmEstoqueComponent } from './cmp-quantidade-em-estoque.component';

@NgModule({
    imports: [UIComponentsModule],
    exports: [CmpQuantidadeEmEstoqueComponent],
    declarations: [CmpQuantidadeEmEstoqueComponent],
})
export class QuantidadeEmEstoqueSharedComponentsModule {}
