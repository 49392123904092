import { NgModule } from '@angular/core';

import { UIComponentsModule } from '../../../../ui-components/ui-components.module';
import { NotasRecebidasTabsTitleComponent } from './notas-recebidas-tabs-title.component';

@NgModule({
    imports: [UIComponentsModule],
    exports: [NotasRecebidasTabsTitleComponent],
    declarations: [NotasRecebidasTabsTitleComponent],
})
export class NotasRecebidasTabsTitleModule {}
