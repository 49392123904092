import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChild,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { AppRoutingService } from '../../../app-routing.service';
import { AppSettings } from '../../../app.settings';

@Injectable()
export class CrmGrupoDeClientePrimeirosPassosGuard implements CanActivateChild {
    constructor(
        private http: HttpClient,
        private _routingService: AppRoutingService
    ) {}

    canActivateChild(
        route: ActivatedRouteSnapshot,
        _: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        if (
            this._routingService.primeirosPassosGrupoDeCliente$.value == false
        ) {
            return true;
        }

        const path = route.routeConfig.path;
        if (path == '') {
            return this._redirecionarPrimeirosPassos(route);
        } else {
            return true;
        }
    }

    private _redirecionarPrimeirosPassos(
        route: ActivatedRouteSnapshot
    ): Observable<boolean> | boolean {
        const URL_API = `${AppSettings.API_ENDPOINT_SISTEMA}/sistema/primeiros-passos/grupo-de-cliente`;

        return this.http
            .get<{ $id: number; primeirosPassos: boolean }>(URL_API)
            .pipe(
                take(1),
                map((response) => {
                    if (response.primeirosPassos) {
                        this._routingService.cadastroDeGrupoDeClientePrimeirosPassos();
                        return false;
                    } else {
                        this._routingService.primeirosPassosGrupoDeCliente$.next(
                            false
                        );
                        this._routingService.cadastroDeGrupoDeCliente();
                        return false;
                    }
                }),
                catchError(() => {
                    return of(true);
                })
            );
    }
}
