import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MessagePanelModule } from '../../../ui-components/components/panels/message-panel/message-panel.module';
import { WarningMessageGeralComponent } from './warning-message-geral.component';

@NgModule({
    imports: [CommonModule, MessagePanelModule],
    exports: [WarningMessageGeralComponent],
    declarations: [WarningMessageGeralComponent],
    entryComponents: [],
    providers: [],
})
export class WarningMessageGeralModule {}
