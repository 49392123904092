import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { DevextremeComponentsModule } from 'src/app/devextreme-components.module';
import { CmpFornecedorMultiDropboxComponent } from './cmp-fornecedor-multi-dropbox.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FlexLayoutModule,
        DevextremeComponentsModule,
    ],
    exports: [CmpFornecedorMultiDropboxComponent],
    declarations: [CmpFornecedorMultiDropboxComponent],
})
export class FornecedorMultiDropboxModule {}
