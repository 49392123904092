import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
    selector: 'otk-warning-message-geral',
    templateUrl: './warning-message-geral.component.html',
    styleUrls: ['./warning-message-geral.component.scss'],
})
export class WarningMessageGeralComponent implements OnInit {
    msgs: any[] = [];
    @Input() avisoGeral: string;
    @Input() existeAvisoGeral: boolean;

    private _usuarioAutenticadoSubscription: Subscription;

    constructor(private _authService: AuthService) {}

    ngOnInit() {
        this._usuarioAutenticadoSubscription = this._authService
            .getAvisos()
            .subscribe((r) => {
                //console.log('Retorno API:', r);

                this.avisoGeral = r.avisoGeral;
                this.existeAvisoGeral = r.existeAvisoGeral;

                this.mensagemGeral();
            });
    }

    ngOnDestroy() {
        this._usuarioAutenticadoSubscription.unsubscribe();
    }

    actionClose() {
        this.msgs = [];
    }

    private mensagemGeral() {
        this.msgs = [];

        if (this.existeAvisoGeral) {
            let msg = {
                severity: 'info',
                summary: null,
                detail: this.avisoGeral,
            };
            this.msgs.push(msg);
        }

        console.log('Mensagem Geral:', this.msgs);
    }
}
