import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { DevextremeComponentsModule } from 'src/app/devextreme-components.module';
import { CmpContaCorrenteMultiDropboxComponent } from './cmp-conta-corrente-multi-dropbox.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FlexLayoutModule,
        DevextremeComponentsModule,
    ],
    exports: [CmpContaCorrenteMultiDropboxComponent],
    declarations: [CmpContaCorrenteMultiDropboxComponent],
})
export class ContaCorrenteMultiDropboxModule {}
