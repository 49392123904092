import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'otk-cmp-frete-radio-button',
    templateUrl: './cmp-frete-radio-button.component.html',
    styleUrls: ['./cmp-frete-radio-button.component.scss'],
})
export class CmpFreteRadioButtonComponent implements OnInit {
    @Input() caption: string = 'Frete';
    @Input() control: FormControl = new FormControl(null, []);

    constructor() {}

    ngOnInit() {}
}
