import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { AppRoutingService } from '../../../app-routing.service';
import { StateModeEnum } from '../../../app.enums';
import { AppHelper } from '../../../app.helper';
import { ComprasAuthPedidoService } from '../../../auth/compras/compras-auth-pedido.service';

@Component({
    selector: 'otk-compras-ordem-de-compra-status',
    templateUrl: './compras-ordem-de-compra-status.component.html',
    styleUrls: ['./compras-ordem-de-compra-status.component.scss'],
})
export class ComprasOrdemDeCompraStatusComponent implements OnInit {
    private _stateSubscription: Subscription;

    @Input() ordem: any;
    @Input() exibirNumeroDaOrdem: boolean = false;
    @Input() exibirVisualizarPedido: boolean = false;
    @Input() state$: BehaviorSubject<StateModeEnum> =
        new BehaviorSubject<StateModeEnum>(StateModeEnum.browse);

    visible: boolean = false;
    msgs: any[] = [];

    constructor(
        private _routingService: AppRoutingService,
        public comprasAuthPedidoService: ComprasAuthPedidoService
    ) {}

    ngOnInit() {
        this._stateSubscription = this.state$.subscribe(
            (r) => (this.visible = AppHelper.isBrowseSelectedMode(r))
        );
    }

    ngOnChanges() {
        this.msgs = [];
        if (!this.ordem) {
            return;
        }

        if (this.ordem.reprovada) {
            let msg = {
                severity: 'error',
                summary: null,
                detail: this.ordem.status,
            };
            this.msgs.push(msg);
            return;
        }

        if (this.ordem.cancelada) {
            let msg = {
                severity: 'error',
                summary: null,
                detail: this.ordem.status,
            };
            this.msgs.push(msg);
            return;
        }

        if (this.ordem.finalizada) {
            let msg = {
                severity: 'info',
                summary: null,
                detail: this.ordem.status,
            };
            this.msgs.push(msg);
            return;
        }

        if (this.ordem.avisos) {
            let msg = {
                severity: this.ordem.pendente ? 'warn' : 'success',
                summary: null,
                detail: this.ordem.avisos,
            };
            this.msgs.push(msg);
            return;
        }

        if (this.ordem.pendente) {
            let msg = {
                severity: 'warn',
                summary: null,
                detail: this.ordem.status,
            };
            this.msgs.push(msg);
            return;
        }

        if (this.ordem.aprovada || this.ordem.naoRequerAprovacao) {
            let msg = {
                severity: 'success',
                summary: null,
                detail: this.ordem.status,
            };
            this.msgs.push(msg);
            return;
        }
    }

    ngOnDestroy() {
        this._stateSubscription.unsubscribe();
    }

    actionVisualizarOrdemDeCompra(): void {
        if (!this.ordem || !this.ordem.numeroDaOrdem) {
            return;
        }
        this._routingService.ordemDeCompraDetalhe(this.ordem.numeroDaOrdem);
    }

    actionVisualizarOrdemConsolidada(): void {
        if (!this.ordem || !this.ordem.numeroDaOrdemConsolidado) {
            return;
        }
        this._routingService.ordemDeCompraDetalhe(
            this.ordem.numeroDaOrdemConsolidado
        );
    }

    actionVisualizarPedido(): void {
        if (this.ordem && this.ordem.numeroDaOrdem) {
            this._routingService.pedidoDeCompraPesquisarPorNumeroDaOrdem(
                this.ordem.numeroDaOrdem
            );
        }
    }
}
