import { UIComponentsModule } from './../../../ui-components/ui-components.module';
import { NgModule } from '@angular/core';
import { CmpClassificacaoAuxiliarGeralEstoqueMultiDropboxComponent } from './cmp-classificacao-auxiliar-geral-estoque-multi-dropbox.component';

@NgModule({
    imports: [UIComponentsModule],
    exports: [CmpClassificacaoAuxiliarGeralEstoqueMultiDropboxComponent],
    declarations: [CmpClassificacaoAuxiliarGeralEstoqueMultiDropboxComponent],
})
export class ClassificaoAuxiliarGeralEstoqueMultiDropboxModule {}
