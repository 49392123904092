import { Component, Input } from '@angular/core';

@Component({
    selector: 'otk-mensagem-info-status',
    templateUrl: './mensagem-info-status.component.html',
    styleUrls: ['./mensagem-info-status.component.scss'],
})
export class MensagemInfoStatusComponent {
    @Input() msgs: any[] = [];

    constructor() {}

    ngOnInit() {}

    ngOnDestroy() {}

    closeMessage(msg: any) {
        const index = this.msgs.indexOf(msg);
        if (index !== -1) {
            this.msgs.splice(index, 1);
        }
    }

    getMessageClasses(msg: any) {
        return {
            info: msg.type === 'info',
            infoStatus: msg.type === 'infoStatus',
        };
    }
}
