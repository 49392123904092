import { UIComponentsModule } from './../../../ui-components/ui-components.module';
import { NgModule } from '@angular/core';
import { CmpClassificacaoAuxiliarFinanceiroMultiDropboxComponent } from './cmp-classificacao-auxiliar-financeiro-multi-dropbox.component';

@NgModule({
    imports: [UIComponentsModule],
    exports: [CmpClassificacaoAuxiliarFinanceiroMultiDropboxComponent],
    declarations: [CmpClassificacaoAuxiliarFinanceiroMultiDropboxComponent],
})
export class ClassificaoAuxiliarFinanceiroMultiDropboxModule {}
