import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/primeng';

@Component({
    selector: 'otk-compras-fornecedor-pedidos-avaliacao-button',
    templateUrl: './compras-fornecedor-pedidos-avaliacao-button.component.html',
    styleUrls: ['./compras-fornecedor-pedidos-avaliacao-button.component.scss'],
})
export class ComprasFornecedorPedidosAvaliacaoButtonComponent
    implements OnInit
{
    @Input() numeroDoPedido: number;
    @Input() notaDeAvaliacao: number;
    @Input() observacaoNotaDeAvaliacao: string;
    @Input() atualizacaoDaNotaDeAvaliacao: string;
    @Input() exibirSemAvaliacao: boolean = false;
    @Input() exibirInformacoes: boolean = true;
    @Input() tooltip: string = 'Clique para visualizar';

    withAnimationOptionsVisible: boolean;

    constructor() {
        this.withAnimationOptionsVisible = false;
    }

    ngOnInit() {}

    get temNotaDeAvaliacao(): boolean {
        if (!this.numeroDoPedido) {
            return false;
        }

        if (this.notaDeAvaliacao == null || this.notaDeAvaliacao == undefined) {
            return false;
        }

        return this.notaDeAvaliacao >= 1;
    }

    toggleWithAnimationOptions() {
        this.withAnimationOptionsVisible = !this.withAnimationOptionsVisible;
    }
}
