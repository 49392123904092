import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MensagemInfoStatusComponent } from './mensagem-info-status.component';

@NgModule({
    imports: [CommonModule, FlexLayoutModule],
    exports: [MensagemInfoStatusComponent],
    declarations: [MensagemInfoStatusComponent],
})
export class MensagemInfoStatusModule {}
