import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'otk-misc-people-toggle',
    templateUrl: './misc-people-toggle.component.html',
    styleUrls: ['./misc-people-toggle.component.scss'],
})
export class MiscPeopleToggleComponent implements OnInit {
    @Input() tooltipChecked: string = '';
    @Input() tooltipUnchecked: string = '';
    @Input() checked: boolean = false;
    @Input() visible: boolean = true;
    @Output() change = new EventEmitter<boolean>();

    constructor() {}

    ngOnInit() {}

    ngOnChanges() {}

    actionClick() {
        this.checked = !this.checked;
        this.change.emit(this.checked);
    }
}
