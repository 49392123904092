import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
} from '@angular/material';

import { MiscPeopleToggleComponent } from './misc-people-toggle.component';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
    ],
    exports: [MiscPeopleToggleComponent],
    declarations: [MiscPeopleToggleComponent],
    providers: [],
})
export class CadastrosColaboradorComponentsModule {}
